import { RS_WAYS_TO_NUMBER } from '../constants';
import { getRoutePolylineCoordinates, getRouteStationsCoordinates } from './';

export const getExtendedRoute = (route, trips, shapes, stops, stopTimes) => ({
  ...route,
  routeWayCoordinates: getRoutePolylineCoordinates(
    route,
    trips,
    shapes,
    RS_WAYS_TO_NUMBER.TOUR
  ),
  routeRoundWayCoordinates: getRoutePolylineCoordinates(
    route,
    trips,
    shapes,
    RS_WAYS_TO_NUMBER.RETURN
  ),
  routeWayStations: getRouteStationsCoordinates(
    route,
    trips,
    stops,
    stopTimes,
    RS_WAYS_TO_NUMBER.TOUR
  ),
  routeRoundWayStations: getRouteStationsCoordinates(
    route,
    trips,
    stops,
    stopTimes,
    RS_WAYS_TO_NUMBER.RETURN
  ),
});

export default getExtendedRoute;
