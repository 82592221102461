import { INSTANCE_VEHICLE_TYPE_ALL, URLS } from '../constants';

const {
  REACT_APP_VEHICLE_TYPES: instanceVehicleTypes,
  REACT_APP_CITY: agencyId,
  REACT_APP_OPEN_DATA_X_API_KEY: openDataXApiKey,
} = process.env;

const {
  GTFS_URL,
  GTFS_ROUTES_PATH,
  GTFS_TRIPS_PATH,
  GTFS_SHAPES_PATH,
  GTFS_STOPS_PATH,
  GTFS_STOP_TIMES_PATH,
  GTFS_VEHICLES_PATH,
} = URLS;

const gtfsHeaders = {
  'X-API-KEY': openDataXApiKey,
  'x-Agency-Id': agencyId,
  'Content-Type': 'application/json',
};

const filterRoutesByVehicleTypes = (routes, vehicleTypes) =>
  routes.filter((route) => vehicleTypes.includes(String(route.route_type)));

export const fetchAllData = async () => {
  try {
    const requestUrls = [
      `${GTFS_URL}${GTFS_ROUTES_PATH}`,
      `${GTFS_URL}${GTFS_TRIPS_PATH}`,
      `${GTFS_URL}${GTFS_SHAPES_PATH}`,
      `${GTFS_URL}${GTFS_STOPS_PATH}`,
      `${GTFS_URL}${GTFS_STOP_TIMES_PATH}`,
    ];

    const requestPromises = requestUrls.map((url) =>
      fetch(url, {
        headers: gtfsHeaders,
      }).then(async (r) => {
        if (!r.ok) {
          return { valid: false, data: [] };
        }
        return { valid: true, data: await r.json() };
      })
    );
    const responses = await Promise.all(requestPromises);

    const [routes, trips, shapes, stops, stopTimes, vehicles] = responses.map(
      (r) => r.data
    );
    return {
      valid: !responses.some((r) => !r.valid),
      data: {
        routes:
          instanceVehicleTypes === INSTANCE_VEHICLE_TYPE_ALL
            ? routes
            : filterRoutesByVehicleTypes(
                routes,
                instanceVehicleTypes.split(',')
              ),
        trips,
        shapes,
        stops,
        stopTimes,
        vehicles,
      },
    };
  } catch (error) {
    console.error(error);
  }
};

export const fetchVehiclesData = async () => {
  const vehicles = await fetch(`${GTFS_URL}${GTFS_VEHICLES_PATH}`, {
    headers: gtfsHeaders,
  }).then((r) => r.json());

  return vehicles;
};
