import React from 'react';
import { Marker } from 'react-leaflet';

const DirectionArrowMarker = ({ icon, station }) => {
  return (
    <Marker
      position={[station.lat, station.lng]}
      icon={icon}
      zIndexOffset={2000}
    />
  );
};

export default DirectionArrowMarker;

React.memo(
  DirectionArrowMarker,
  (prevProps, nextProps) =>
    prevProps.station.stationID === nextProps.station.stationID &&
    prevProps.icon.options.html === nextProps.icon.options.html
);
