import { useState, useEffect } from 'react';
import { getExtendedRoute } from '../utils';

const searchParams = new URLSearchParams(window.location.search);
const routeId = searchParams.get('routeId');

const usePreselectRoute = (
  { routes, trips, shapes, stops, stopTimes },
  setSelectedRoute,
  setAutocompleteValue,
  setStartFetchVehicles
) => {
  const [validRouteId, setValidRouteId] = useState(false);

  useEffect(() => {
    if (routes?.length && routeId) {
      setStartFetchVehicles(true);
      const _selectedRoute = routes.find(
        (route) => route.route_id === parseInt(routeId, 10)
      );

      if (_selectedRoute) {
        const extendedRoute = getExtendedRoute(
          _selectedRoute,
          trips,
          shapes,
          stops,
          stopTimes
        );
        setSelectedRoute(extendedRoute);
        setAutocompleteValue(extendedRoute?.route_long_name || '');
        setValidRouteId(true);
      }
    }
  }, [
    routes,
    trips,
    shapes,
    stops,
    stopTimes,
    setSelectedRoute,
    setAutocompleteValue,
    setStartFetchVehicles,
  ]);

  return validRouteId;
};

export default usePreselectRoute;
