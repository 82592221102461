import { styled } from '@mui/material/styles';

const SwitchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
  height: 40px;
  padding: 0 16px;
  z-index: 3;
  box-shadow: 0 2px 2px 0px rgba(204, 204, 204, 0.8);
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  width: fit-content;
  pointer-events: auto;
`;

const OptionsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionText = styled('span')`
  color: #000000;
  font-weight: bold;
  font-size: 16px;
`;

const SwitchDiv = styled('div')`
  width: 42px;
  height: 22px;
  margin: 0 10px;
  background-color: #000000;
  border-radius: 50px;
  display: flex;
`;

const Thumb = styled('div')`
  width: 18px;
  height: 18px;
  margin: 2px;
  border-radius: 50%;
  transition: transform 200ms linear;
`;

const CONTAINER_ID = 'container';
const LEFT_OPTION_ID = 'leftOption';
const RIGHT_OPTION_ID = 'rightOption';
const SWITCH_ID = 'switch';

const Switch = ({
  color,
  leftOption,
  rightOption,
  value,
  onChange,
  disabled,
}) => {
  const toggleSwitch = (event) => {
    const { id } = event.target;

    if (disabled) return;
    if (id === CONTAINER_ID) return;
    if (id === LEFT_OPTION_ID && value === leftOption.value) return;
    if (id === RIGHT_OPTION_ID && value === rightOption.value) return;

    event.stopPropagation();

    if (value === leftOption.value) {
      onChange(rightOption.value);
    } else {
      onChange(leftOption.value);
    }
  };

  return (
    <SwitchContainer
      id={CONTAINER_ID}
      sx={{ cursor: disabled ? 'default' : 'pointer' }}
      onClick={toggleSwitch}
    >
      <OptionsWrapper sx={{ opacity: disabled ? 0.2 : 1 }}>
        <OptionText
          id={LEFT_OPTION_ID}
          sx={{ color: leftOption.value === value ? 'inherit' : '#b4b4b4' }}
        >
          {leftOption.label}
        </OptionText>
        <SwitchDiv id={SWITCH_ID}>
          <Thumb
            sx={{
              backgroundColor: color,
              transform:
                rightOption.value === value ? 'translateX(20px)' : 'none',
            }}
          />
        </SwitchDiv>
        <OptionText
          id={RIGHT_OPTION_ID}
          sx={{ color: rightOption.value === value ? 'inherit' : '#b4b4b4' }}
        >
          {rightOption.label}
        </OptionText>
      </OptionsWrapper>
    </SwitchContainer>
  );
};

export default Switch;
