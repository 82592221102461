import React, { useEffect, useRef } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { styled } from '@mui/material/styles';

const CustomPopup = styled(Popup)`
  text-align: center;
`;

const StationMarker = ({ station, icon, openPopup }) => {
  const markerRef = useRef();

  useEffect(() => {
    if (markerRef.current && openPopup) {
      markerRef.current.openPopup();
    }
  }, [openPopup]);

  return (
    <Marker
      position={[station.lat, station.lng]}
      icon={icon}
      eventHandlers={{
        onclick: (event) => event.target.openPopup(),
      }}
      zIndexOffset={2000}
      ref={markerRef}
    >
      <CustomPopup autoPan={false}>{station.stationName}</CustomPopup>
    </Marker>
  );
};

export default React.memo(
  StationMarker,
  (prevProps, nextProps) =>
    prevProps.station.stationID === nextProps.station.stationID &&
    prevProps.icon.options.html === nextProps.icon.options.html &&
    prevProps.openPopup === nextProps.openPopup
);
