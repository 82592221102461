import { useState, useEffect } from 'react';

import { fetchAllData } from '../actions';

const useFetchData = () => {
  const [isLoadingGtfsData, setIsLoadingGtfsData] = useState(null);
  const [gtfsData, setGtfsData] = useState({});

  useEffect(() => {
    (async () => {
      setIsLoadingGtfsData(true);
      const {
        valid,
        data: { routes, trips, shapes, stops, stopTimes },
      } = await fetchAllData();

      if (valid) {
        setIsLoadingGtfsData(false);
        setGtfsData({
          routes: routes.sort((r1, r2) =>
            r1.route_short_name.localeCompare(r2.route_short_name, undefined, {
              numeric: true,
            })
          ),
          trips,
          shapes,
          stops,
          stopTimes,
        });
      }
    })();
  }, []);

  return { gtfsData, isLoadingGtfsData };
};

export default useFetchData;
