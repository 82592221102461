import { useState, useEffect, useRef, useCallback } from 'react';

import { fetchVehiclesData } from '../actions';
import { getExtendedVehicles } from '../utils';
import { SELECTED_CITY_CONFIG } from '../constants';

const { VEHICLE_FETCH_INTERVAL_MS } = SELECTED_CITY_CONFIG;

const useFetchVehicles = (trips) => {
  let vhFetchInterval = useRef();
  const [vehicles, setVehicles] = useState([]);
  const [startFetchVehicles, setStartFetchVehicles] = useState(false);

  const fetchVehiclesAtInterval = useCallback(async () => {
    const vehicles = await fetchVehiclesData();
    setVehicles(getExtendedVehicles(vehicles, trips));
  }, [trips]);

  const resetFetchVehicles = () => {
    setStartFetchVehicles(false);
    clearInterval(vhFetchInterval.current);
  };

  useEffect(() => {
    if (!trips || !startFetchVehicles) return;


    (async () => {
      await fetchVehiclesAtInterval();

      vhFetchInterval.current = setInterval(
        fetchVehiclesAtInterval,
        VEHICLE_FETCH_INTERVAL_MS
      );
    })();

    return resetFetchVehicles;
  }, [fetchVehiclesAtInterval, trips, startFetchVehicles]);

  return { vehicles, setStartFetchVehicles, resetFetchVehicles };
};

export default useFetchVehicles;
