const computeArrowIconOffset = (rotatingDeg, zoomLevel) => {
  if (
    (rotatingDeg >= 270 && rotatingDeg < 360) ||
    (rotatingDeg >= 0 && rotatingDeg < 90)
  ) {
    return [0, -15 - (zoomLevel - 12) * 1.42];
  }

  if (rotatingDeg >= 90 && rotatingDeg < 270) {
    return [0, 50];
  }

  return [0, -10];
};

export default computeArrowIconOffset;
