const getRouteStationsCoordinates = (
  route,
  trips,
  stops,
  stopTimes,
  direction
) => {
  const trip = trips.find(
    (trip) =>
      trip.route_id === route.route_id && trip.direction_id === direction
  );

  if (!trip) return [];

  const matchingStops = stopTimes.filter(
    (stopTime) => stopTime.trip_id === trip.trip_id
  );

  const stations = [];
  matchingStops.forEach((matchingStop) => {
    stops.forEach((stop) => {
      if (Number(matchingStop.stop_id) === stop.stop_id) {
        stations.push({
          stationID: stop.stop_id,
          stationName: stop.stop_name,
          lat: stop.stop_lat,
          lng: stop.stop_lon,
          stop_sequence: matchingStop.stop_sequence,
        });
      }
    });
  });

  return stations.sort((a, b) => a.stop_sequence - b.stop_sequence);
};

export default getRouteStationsCoordinates;
