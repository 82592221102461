import { useCallback, useMemo } from 'react';
import L from 'leaflet';
import {
  generateDirectionArrowSvg,
  getDirectionArrowSizeByZoom,
} from '../utils';
import { MAX_ZOOM_LEVEL } from '../constants';

const useGetPolylineDecoratorArrow = ({ bgColor, zoomLevel }) => {
  const directionArrowSvg = generateDirectionArrowSvg('white');
  const arrowSizeByZoom = getDirectionArrowSizeByZoom(zoomLevel);

  const arrowIcon = useCallback(
    (size, bgColor) =>
      new L.DivIcon({
        iconAnchor: [size / 2, size / 2],
        className: '',
        html: `
        <div
          style="width: ${size}px; height: ${size}px; background-color: ${bgColor};"
          class="polyline-arrow"
        >
          ${directionArrowSvg}
        </div>
      `,
      }),
    [directionArrowSvg]
  );

  const patterns = useMemo(
    () => [
      {
        offset: 50,
        offsetEnd: 50,
        repeat: 500,
        symbol: L.Symbol.marker({
          markerOptions: { icon: arrowIcon(arrowSizeByZoom, bgColor) },
          rotate: true,
        }),
      },
    ],
    [arrowIcon, arrowSizeByZoom, bgColor]
  );

  if (zoomLevel < MAX_ZOOM_LEVEL - 3) return [];
  return patterns;
};

export default useGetPolylineDecoratorArrow;
