import { RS_WAYS, RS_WAYS_TO_NUMBER } from '../constants';

const getExtendedVehicles = (vehicles, trips) => {
  return vehicles.map((vehicle) => {
    let vehicleRouteWay = null;
    const vehicleTrip = trips.find((trip) => trip.trip_id === vehicle.trip_id);

    if (vehicleTrip) {
      vehicleRouteWay =
        vehicleTrip.direction_id === RS_WAYS_TO_NUMBER.TOUR
          ? RS_WAYS.TOUR
          : RS_WAYS.RETURN;
    }

    return {
      vehicleID: vehicle.id,
      vehicleRouteID: vehicle.route_id,
      vehicleRouteWay,
      vehicleType: vehicle.vehicle_type,
      vehicleName: vehicle.label,
      vehicleLat: vehicle.latitude,
      vehicleLong: vehicle.longitude,
      vehicleDate: vehicle.timestamp,
      vehicleSpeed: vehicle.speed,
    };
  });
};

export default getExtendedVehicles;
