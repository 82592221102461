import { useEffect, useRef } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';

const PolylineDecorator = ({ patterns, positions, pathOptions }) => {
  const map = useMap();
  const polylineRef = useRef(null);
  const polylineDecoratorRef = useRef(null);

  useEffect(() => {
    if (!map) return;

    polylineRef.current = L.polyline(positions, pathOptions).addTo(map);
    polylineDecoratorRef.current = L.polylineDecorator(positions, {
      patterns,
    }).addTo(map);

    return () => {
      polylineRef.current.removeFrom(map);
      polylineDecoratorRef.current.setPatterns([]);

      polylineRef.current = null;
    };
  }, [map, pathOptions, patterns, positions]);

  return null;
};

export default PolylineDecorator;
