import { styled } from '@mui/material/styles';
import loaderGif from '../assets/img/loader.gif';

const SpinnerWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 1000;
`;

const Background = styled('div')`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

const Animation = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
`;
const AnimationText = styled('div')`
  text-align: center;
  font-weight: bold;
  color: #f4f4f4;
`;

const AnimationImg = styled('img')`
  width: 100%;
`;

const Spinner = () => {
  return (
    <SpinnerWrapper>
      <Background />
      <Animation>
        <AnimationImg src={loaderGif} alt="loader-gif" />
        <AnimationText>Loading...</AnimationText>
      </Animation>
    </SpinnerWrapper>
  );
};

export default Spinner;
