import { RS_WAYS } from '../constants';

export const getIntermediateStationsZoomAdjustment = (zoomLevel) =>
  zoomLevel > 13 ? `${-6 + zoomLevel}px` : '6px';

export const getTerminalStationsZoomAdjustment = (zoomLevel) =>
  zoomLevel > 13 ? `${-2 + zoomLevel}px` : '8px';

export const getDirectionArrowSizeByZoom = (zoomLevel) =>
  20 - 0.5 * (zoomLevel - 15);

export const getLineNumberFontSize = (length) => {
  if (length <= 2) return '16px';
  if (length === 3) return '11px';
  if (length === 4) return '9px';
  if (length === 5) return '7px';
};

export const getStationsKeyByWay = (rsWay) =>
  rsWay === RS_WAYS.TOUR ? 'routeWayStations' : 'routeRoundWayStations';

export const getCoordinatesKeyByWay = (rsWay) =>
  rsWay === RS_WAYS.TOUR ? 'routeWayCoordinates' : 'routeRoundWayCoordinates';

export const generateDirectionArrowSvg = (color) =>
  `<svg fill=${color} width="10" height="12.85" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.51717 16.4348L8.51716 5.3413L11.2864 8.07654C11.9044 8.68697 12.9185 8.68697 13.5365 8.07654C14.1545 7.4661 14.1545 6.48001 13.5365 5.86958L8.05763 0.457825C7.43964 -0.152608 6.44135 -0.152608 5.82336 0.457825L0.463491 5.86958C-0.154498 6.48001 -0.154498 7.4661 0.463492 8.07654C1.08148 8.68697 2.07978 8.68697 2.69777 8.07654L5.34799 5.3413L5.348 16.4348C5.348 17.2957 6.06106 18 6.93258 18C7.80411 18 8.51717 17.2957 8.51717 16.4348Z"/>
</svg>
`;

export const isMobile = () => /Android|iPhone/i.test(navigator.userAgent);

export const capitalizeFirstLetter = (str) => {
  str = String(str);
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export { default as computeArrowIconOffset } from './computeArrowIconOffset';
export { default as computeRotatingDeg } from './computeRotatingDeg';
export { default as getExtendedRoute } from './getExtendedRoute';
export { default as getExtendedVehicles } from './getExtendedVehicles';
export { default as getMiddleCoordinateOnRoute } from './getMiddleCoordinateOnRoute';
export { default as getRoutePolylineCoordinates } from './getRoutePolylineCoordinates';
export { default as getRouteStationsCoordinates } from './getRouteStationsCoordinates';
// export { default as findMiddlePointIndex } from './findMiddlePointIndex';
// export { default as calculateDistanceBetweenTwoPoints } from './calculateDistanceBetweenTwoPoints';
// export { default as findClosestCoordinates } from './findClosestCoordinates';
