import { useCallback, useMemo } from 'react';
import L from 'leaflet';
import {
  generateDirectionArrowSvg,
  computeArrowIconOffset,
  computeRotatingDeg,
  getDirectionArrowSizeByZoom,
  getTerminalStationsZoomAdjustment,
  getIntermediateStationsZoomAdjustment,
} from '../utils';
import { MAX_ZOOM_LEVEL } from '../constants';

const useGetStationIcons = ({ color, zoomLevel, station1, station2 }) => {
  const directionArrowSvg = generateDirectionArrowSvg('white');

  const rotatingDeg = useMemo(
    () => computeRotatingDeg(station1, station2),
    [station1, station2]
  );

  const iconAnchor = useMemo(
    () => computeArrowIconOffset(rotatingDeg, zoomLevel),
    [rotatingDeg, zoomLevel]
  );

  const generateStationIcon = useCallback(
    (size, color, _class) =>
      new L.DivIcon({
        className: 'icon-div',
        html: `<div style="width: ${size}; height: ${size}; border-color: ${color};" class="${_class}"/>`,
      }),
    []
  );

  const generateDirectionArrowIcon = useCallback(
    (size) =>
      new L.DivIcon({
        iconAnchor,
        className: '',
        html: `<div 
        style="transform: rotate(${rotatingDeg}deg); width: ${size}px; height: ${size}px; background-color: ${color};"
        class="polyline-arrow"
       >${directionArrowSvg}</div>`,
      }),
    [iconAnchor, rotatingDeg, color, directionArrowSvg]
  );

  const terminalStationIcon = generateStationIcon(
    getTerminalStationsZoomAdjustment(zoomLevel),
    color,
    'terminal-station-dot'
  );
  const intermediateStationIcon = generateStationIcon(
    getIntermediateStationsZoomAdjustment(zoomLevel),
    color,
    'station-dot'
  );

  let arrowIcon = generateDirectionArrowIcon(
    getDirectionArrowSizeByZoom(zoomLevel)
  );

  if (zoomLevel >= MAX_ZOOM_LEVEL - 3) {
    arrowIcon = null;
  }

  return { terminalStationIcon, intermediateStationIcon, arrowIcon };
};

export default useGetStationIcons;
