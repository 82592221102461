import { useState, useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { getMiddleCoordinateOnRoute } from '../utils';
import { RS_WAYS, MAX_ZOOM_LEVEL, INITIAL_ZOOM_LEVEL } from '../constants';

const searchParams = new URLSearchParams(window.location.search);
const routeId = searchParams.get('routeId');
const stationId = searchParams.get('stationId');
const vehicleName = searchParams.get('vehicleName');
const rsWay = searchParams.get('rsWay');

const useAutoZoom = (
  selectedRoute,
  vehicles,
  setRsWay,
  userHasChangedRsWay
) => {
  const map = useMap();
  const [zoomed, setZoomed] = useState(false);
  const [zoomedStationId, setZoomedStationId] = useState(null);
  const [zoomedVehicleName, setZoomedVehicleName] = useState(null);

  const findStationById = (station) => String(station.stationID) === stationId;

  const findVehicleById = (vehicle) => vehicle.vehicleName === vehicleName;

  useEffect(() => {
    if (selectedRoute && routeId && !stationId && !vehicleName && !rsWay) {
      const middleCoordinateOnRoute = getMiddleCoordinateOnRoute(
        selectedRoute,
        RS_WAYS.TOUR
      );
      map.setView(middleCoordinateOnRoute, INITIAL_ZOOM_LEVEL);
      setZoomed(true);
    }
  }, [map, selectedRoute]);

  useEffect(() => {
    if (selectedRoute && stationId) {
      const stationOnTour =
        selectedRoute['routeWayStations'].find(findStationById);
      const stationOnReturn =
        selectedRoute['routeRoundWayStations'].find(findStationById);

      if (stationOnTour) {
        map.setView([stationOnTour.lat, stationOnTour.lng], MAX_ZOOM_LEVEL - 2);
        setZoomed(true);
        setZoomedStationId(Number(stationId));
      }

      if (stationOnReturn) {
        !userHasChangedRsWay && setRsWay(RS_WAYS.RETURN);
        map.setView(
          [stationOnReturn.lat, stationOnReturn.lng],
          MAX_ZOOM_LEVEL - 2
        );
        setZoomedStationId(Number(stationId));
        setZoomed(true);
      }
    }

    if (selectedRoute && vehicleName && rsWay) {
      const vehicle = vehicles.find(findVehicleById);

      if (vehicle) {
        map.setView(
          [vehicle?.vehicleLat, vehicle?.vehicleLong],
          MAX_ZOOM_LEVEL - 2
        );
        !userHasChangedRsWay && setRsWay(rsWay);
        setZoomed(true);
        setZoomedVehicleName(vehicleName);
      }
    }
  }, [map, selectedRoute, vehicles, setRsWay, userHasChangedRsWay]);

  return { zoomed, zoomedStationId, zoomedVehicleName };
};

export default useAutoZoom;
