import L from 'leaflet';
import busPng from '../assets/img/marker-bus-mauve.png';
import tramPng from '../assets/img/marker-tram-red.png';
import trolleyPng from '../assets/img/marker-trolley-blue.png';

const busIcon = new L.Icon({
  iconUrl: busPng,
  iconRetinaUrl: busPng,
  iconSize: new L.Point(30, 36.75),
  iconAnchor: [15, 36.75], // point of the icon which will correspond to marker's location
  shadowAnchor: [0, 0], // the same for the shadow
  popupAnchor: [0, -25], // point from which the popup
});
const tramIcon = new L.Icon({
  iconUrl: tramPng,
  iconRetinaUrl: tramPng,
  iconSize: new L.Point(30, 36.75),
  iconAnchor: [15, 36.75],
  shadowAnchor: [0, 0],
  popupAnchor: [0, -25],
});
const trolleyIcon = new L.Icon({
  iconUrl: trolleyPng,
  iconRetinaUrl: trolleyPng,
  iconSize: new L.Point(30, 36.75),
  iconAnchor: [15, 36.75],
  shadowAnchor: [0, 0],
  popupAnchor: [0, -25],
});

const CITIES_CONFIG = {
  IASI: {
    LOCATION: [47.151726, 27.587914],
    COLOR: '#00A450',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
  },
  CLUJ: {
    LOCATION: [46.76949812802645, 23.590908050537113],

    COLOR: '#A53692',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
  },
  CHISINAU: {
    LOCATION: [47.0105, 28.8638],

    COLOR: '#1051D3',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
  },
  BOTOSANI: {
    LOCATION: [47.74074, 26.66627],

    COLOR: '#2E75B5',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
  },
};

export const URLS = {
  GTFS_URL: 'https://api.tranzy.dev/v1/opendata',
  GTFS_ROUTES_PATH: '/routes',
  GTFS_TRIPS_PATH: '/trips',
  GTFS_SHAPES_PATH: '/shapes',
  GTFS_STOPS_PATH: '/stops',
  GTFS_STOP_TIMES_PATH: '/stop_times',
  GTFS_VEHICLES_PATH: '/vehicles',
};

export const ONE_HOUR_IN_SECONDS = 3600;

export const VEHICLE_TYPES_ICONS = {
  0: tramIcon,
  3: busIcon,
  11: trolleyIcon,
  null: busIcon,
  undefined: busIcon,
};

export const VEHICLE_NUMBER_TO_TYPE = {
  0: 'tram',
  3: 'bus',
  11: 'trolley',
};

export const VEHICLE_TYPE_TO_NUMBER = {
  tram: 0,
  bus: 3,
  trolley: 11,
};

export const VEHICLE_TYPES = {
  all: 'all',
  bus: 'bus',
  tram: 'tram',
  trolley: 'trolley',
};
export const ROUTE_COLOR = '#A2238E';
export const RS_WAYS = {
  TOUR: 'way',
  RETURN: 'roundWay',
};
export const RS_WAYS_TO_NUMBER = {
  TOUR: 0,
  RETURN: 1,
};

export const INITIAL_ZOOM_LEVEL = 12;
export const MIN_ZOOM_LEVEL = 11;
export const MAX_ZOOM_LEVEL = 18;
export const CITY_NUMBERS = {
  1: 'IASI',
  2: 'CLUJ',
  4: 'CHISINAU',
  6: 'BOTOSANI',
};

export const INSTANCE_VEHICLE_TYPE_ALL = 'ALL';

export const SELECTED_CITY_CONFIG =
  CITIES_CONFIG[CITY_NUMBERS[process.env.REACT_APP_CITY]];

export { t } from './translation';
