import { useState, useRef } from 'react';
import {
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from './Switch';
import {
  RS_WAYS,
  ROUTE_COLOR,
  t,
  VEHICLE_TYPES,
  VEHICLE_NUMBER_TO_TYPE,
  VEHICLE_TYPE_TO_NUMBER,
} from '../constants';
import { capitalizeFirstLetter, getLineNumberFontSize } from '../utils';
import BusIcon from '../assets/img/bus.svg';
import TramIcon from '../assets/img/tram.svg';
import TrolleyIcon from '../assets/img/trolley.svg';
import ArrowDown from '../assets/img/arrowDown.svg';
// import ClearIcon from '../assets/img/clearIcon.svg';

const HeaderWrapper = styled('div')`
  position: absolute;
  top: 14px;
  z-index: 1000;
  width: 100%;
  padding: 0 10px;
  pointer-events: none;

  @media screen and (max-width: 480px) {
    left: 0;
    width: calc(100% - 16px);
  }
`;

const CustomAutocomplete = styled(Autocomplete)`
  z-index: 3;
  box-shadow: 0 2px 2px 0px rgba(204, 204, 204, 0.8);
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  pointer-events: auto;
`;

const CustomPaper = styled(Paper)`
  background-color: #f4f4f4;
`;

const LineNumberWrapper = styled('div')`
  font-weight: bold;

  position: absolute;
  top: 14px;
  left: 17px;

  ${(props) => (props.disabled ? 'opacity: 0.5;' : '')}
`;

const LineNumber = styled('div')`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AutocompleteOptionLi = styled('li')`
  font-weight: bold;

  &:hover {
    background-color: #ffffff !important;
  }
`;

const CustomGrid = styled(Grid)`
  margin-top: 0px;
`;

const Filters = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Filter = styled('div')`
  background-color: #f4f4f4;
  box-shadow: 0 2px 2px 0px rgba(204, 204, 204, 0.8);
  padding: 10px;
  width: 54px;
  height: 54px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  margin-right: 8px;
  pointer-events: auto;

  ${({ active }) =>
    active
      ? 'background-color: #ffffff; opacity: 1; width: 58px; height: 58px;'
      : ''}

  &:last-child {
    border: none;
  }
}`;

const FilterImg = styled('img')`
  width: 20px;
`;

const FilterTrolleyImg = styled('img')`
  width: 22px;
  padding-bottom: 5px;
`;

const ArrowDownImg = styled('img')`
  width: 20px;
`;

// const ClearIconImg = styled('img')`
//   width: 12px;
// `;

const initialFilteredVehicleTypes = {
  all: true,
  bus: false,
  tram: false,
  trolley: false,
};

function Header({
  cityColor,
  routes,
  selectedRoute,
  setSelectedRoute,
  rsWay,
  setRsWay,
  disableAutocomplete,
  autocompleteValue,
  setAutocompleteValue,
}) {
  const [filteredVehicleTypes, setFilteredVehicleTypes] = useState(
    initialFilteredVehicleTypes
  );

  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const autocompleteOpenTimeExpiredRef = useRef(true);

  const filterOptions = (options, state) => {
    let optionsFiltered = options.filter(
      (option) =>
        `${option.route_short_name}: ${option.route_long_name}`
          .toUpperCase()
          .indexOf(state.inputValue.toUpperCase()) !== -1
    );

    if (filteredVehicleTypes.all === false) {
      optionsFiltered = optionsFiltered.filter(
        (option) =>
          filteredVehicleTypes[VEHICLE_NUMBER_TO_TYPE[option.route_type]]
      );
    }

    return optionsFiltered;
  };

  const renderAutocompleteOption = (props, route) => (
    <AutocompleteOptionLi
      {...props}
      key={`${route?.route_short_name}: ${route?.route_long_name}`}
    >
      <div>
        <LineNumber
          sx={{
            border: `3px solid ${route?.route_color || ROUTE_COLOR}`,
            color: route?.route_color || ROUTE_COLOR,
            borderRadius:
              route.route_type === VEHICLE_TYPE_TO_NUMBER.tram ? '0%' : '50%',
            fontSize: getLineNumberFontSize(route?.route_short_name.length),
          }}
        >
          {capitalizeFirstLetter(route?.route_short_name)?.replace(/\s+/g, '')}
        </LineNumber>
      </div>
      {route?.route_long_name}
    </AutocompleteOptionLi>
  );

  const renderAutocompleteInput = (params) => (
    <div>
      {selectedRoute && (
        <LineNumberWrapper disabled={params.disabled}>
          <LineNumber
            sx={{
              border: `3px solid ${selectedRoute?.route_color || ROUTE_COLOR}`,
              color: selectedRoute?.route_color || ROUTE_COLOR,
              borderRadius:
                selectedRoute.route_type === VEHICLE_TYPE_TO_NUMBER.tram
                  ? '0%'
                  : '50%',
              fontSize: getLineNumberFontSize(
                selectedRoute.route_short_name.length
              ),
            }}
          >
            {capitalizeFirstLetter(selectedRoute.route_short_name)?.replace(
              /\s+/g,
              ''
            )}
          </LineNumber>
        </LineNumberWrapper>
      )}

      <TextField
        {...params}
        inputProps={{
          ...params.inputProps,
          value: autocompleteValue,
          style: { fontWeight: 'bold' },
        }}
        placeholder={t('route')}
        onChange={(event) => {
          setAutocompleteValue(event.target.value);
        }}
      />
    </div>
  );

  const onVehicleTypeClick = (vehicleType) => {
    if (vehicleType === VEHICLE_TYPES.all) {
      if (filteredVehicleTypes.all === false) {
        setFilteredVehicleTypes(initialFilteredVehicleTypes);
      }
      return;
    }

    const _filteredVehicleTypes = {
      ...filteredVehicleTypes,
      all: false,
      [vehicleType]: !filteredVehicleTypes[vehicleType],
    };

    const { bus, tram, trolley } = _filteredVehicleTypes;
    if ((bus && tram && trolley) || !(bus || tram || trolley)) {
      setFilteredVehicleTypes(initialFilteredVehicleTypes);
      return;
    }

    setFilteredVehicleTypes(_filteredVehicleTypes);
  };

  const onAutocompleteOpen = () => {
    if (autocompleteOpenTimeExpiredRef.current) {
      setAutocompleteOpen(true);
    }
  };

  const onAutocompleteClose = () => {
    setAutocompleteOpen(false);
    autocompleteOpenTimeExpiredRef.current = false;

    setTimeout(() => {
      autocompleteOpenTimeExpiredRef.current = true;
    }, 200);
  };

  return (
    <HeaderWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl
            fullWidth
            className={selectedRoute ? 'custom-autocomplete' : ''}
          >
            <CustomAutocomplete
              PaperComponent={({ children }) => (
                <CustomPaper>{children}</CustomPaper>
              )}
              open={autocompleteOpen}
              onOpen={onAutocompleteOpen}
              onClose={onAutocompleteClose}
              isOptionEqualToValue={(option, value) =>
                option.route_id === value.route_id
              }
              value={selectedRoute}
              onChange={(_, route) => {
                setAutocompleteValue(route?.route_long_name || '');
                setSelectedRoute(route);
              }}
              id="controllable-states-demo"
              options={routes}
              getOptionLabel={(route) => route?.route_long_name}
              filterOptions={filterOptions}
              renderOption={renderAutocompleteOption}
              renderInput={renderAutocompleteInput}
              blurOnSelect
              disabled={disableAutocomplete}
              loading={!routes.length}
              loadingText={t('loading')}
              popupIcon={<ArrowDownImg src={ArrowDown} alt="arrow-down" />}
              openOnFocus
              // clearIcon={
              //   <ClearIconImg
              //     src={ClearIcon}
              //     alt="clear-icon"
              //   />
              // }
            />
          </FormControl>
        </Grid>
      </Grid>

      <CustomGrid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent={'space-between'}
          >
            {!disableAutocomplete && (
              <Grid item>
                <Filters>
                  <Filter
                    active={filteredVehicleTypes.all}
                    onClick={() => onVehicleTypeClick(VEHICLE_TYPES.all)}
                  >
                    <Typography fontWeight="bold" fontSize={14}>
                      {t('all')}
                    </Typography>
                  </Filter>

                  <Filter
                    active={filteredVehicleTypes.bus}
                    onClick={() => onVehicleTypeClick(VEHICLE_TYPES.bus)}
                  >
                    <FilterImg src={BusIcon} alt="bus-icon" />
                  </Filter>

                  <Filter
                    active={filteredVehicleTypes.tram}
                    onClick={() => onVehicleTypeClick(VEHICLE_TYPES.tram)}
                  >
                    <FilterImg src={TramIcon} alt="tram-icon" />
                  </Filter>

                  <Filter
                    active={filteredVehicleTypes.trolley}
                    onClick={() => onVehicleTypeClick(VEHICLE_TYPES.trolley)}
                  >
                    <FilterTrolleyImg src={TrolleyIcon} alt="trolley-icon" />
                  </Filter>
                </Filters>
              </Grid>
            )}

            <Grid item>
              <Switch
                color={cityColor}
                leftOption={{ label: t('tour'), value: RS_WAYS.TOUR }}
                rightOption={{ label: t('return'), value: RS_WAYS.RETURN }}
                value={rsWay}
                onChange={setRsWay}
                disabled={
                  selectedRoute?.routeRoundWayCoordinates.length === 0 || false
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </CustomGrid>
    </HeaderWrapper>
  );
}

export default Header;
