import { getCoordinatesKeyByWay } from './';

const getMiddleCoordinateOnRoute = (route, _rsWay) => {
  const coordinates = route[getCoordinatesKeyByWay(_rsWay)];

  const middleNo = Math.round(coordinates.length / 2);
  return coordinates[middleNo];
};

export default getMiddleCoordinateOnRoute;
