const getRoutePolylineCoordinates = (route, trips, shapes, direction) => {
  const trip = trips.find(
    (trip) =>
      trip.route_id === route.route_id && trip.direction_id === direction
  );

  if (!trip) return [];

  return shapes
    .filter((shape) => shape.shape_id === trip.shape_id)
    .sort((a, b) => a.shape_pt_sequence - b.shape_pt_sequence)
    .map((shape) => ({
      lat: shape.shape_pt_lat,
      lng: shape.shape_pt_lon,
      shape_pt_sequence: shape.shape_pt_sequence
    }));
};

export default getRoutePolylineCoordinates;
