const computeRotatingDeg = (station1, station2) => {
  if (!station1 || !station2) return null;

  const x1 = Number(station1.lng);
  const x2 = Number(station2.lng);

  const y1 = Number(station1.lat);
  const y2 = Number(station2.lat);

  let alfaDeg = Math.atan2(x2 - x1, y2 - y1) * (180 / Math.PI);

  if (alfaDeg < 0) {
    alfaDeg = 360 + alfaDeg;
  }

  // let rotatingDeg;
  // const degSteps = [45, 90, 135, 180, 225, 270, 315, 360];

  // for (let degStep of degSteps) {
  //   if (alfaDeg < degStep + 5) {
  //     rotatingDeg = degStep;
  //     break;
  //   }
  // }

  return alfaDeg;
};

export default computeRotatingDeg;
