export const LANGUAGES = {
  RO: 'ro',
  EN: 'en',
};

export const i18next = {
  ro: {
    route: 'Selectează traseul',
    tour: 'Tur',
    return: 'Retur',
    parkNo: '# Parc:',
    updated: 'De acum: {secondsNo} secunde',
    speed: 'Viteză: {vehicleSpeed} km/h',
    goingTo: 'Către: ',
    gpsError: 'Eroare date GPS sau alocare',
    loading: 'Se încarcă...',
    all: 'Toate',
  },
  en: {
    route: 'Select route',
    tour: 'Tour',
    return: 'Return',
    parkNo: 'Park #:',
    updated: 'Updated: {secondsNo} seconds ago',
    speed: 'Speed: {vehicleSpeed} km/h',
    goingTo: 'To: ',
    gpsError: 'GPS or allocation data error',
    loading: 'Loadin...',
    all: 'All',
  },
};

const getTranslationsLanguage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const lang = searchParams.get('lang');

  if (lang && Object.keys(i18next).includes(lang)) {
    return i18next[lang];
  }
  return i18next[LANGUAGES.RO];
};

export const t = (key, params) => {
  const translations = getTranslationsLanguage();

  let value = translations[key];
  const paramsKeys = params ? Object.keys(params) : [];
  if (paramsKeys.length) {
    paramsKeys.forEach((paramKey) => {
      const paramValue = params[paramKey];
      value = value.replace(`{${paramKey}}`, paramValue);
    });
  }

  return value;
};
